/*globals
    $:false, ScrollMagic, CountUp, AOS, console
*/

$(document).ready(function onReady($)
{
    "use strict";

    var accordion = $('.acc-header');

    accordion.click(function onClick()
    {
        var thisAcc = $(this);
        // accordion.not(thisAcc).parent().addClass('hide');
        thisAcc.parent().toggleClass('hide');
    });
});